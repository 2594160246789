import {gql} from '@apollo/client';

export const GET_CATALOGS = gql`
  query Catalogs($type: CatalogType!, $country: String!) {
    catalogs(type: $type, country: $country) {
      name
      id
      description
      children {
        name
        id
        description
      }
    }
  }
`;

export const GET_PUBLIC_HOLIDAYS = gql`
  query PublicHolidays($countryCode: String!, $year: Float) {
    publicHolidays(countryCode: $countryCode, year: $year) {
      name
      date
    }
  }
`;

export const GET_LEAVE_HISTORY = gql`
  query LeaveHistory(
    $userId: String!
    $sort: SortInput
    $pageParams: PageInput
  ) {
    leaveHistory(userId: $userId, sort: $sort, pageParams: $pageParams) {
      currentPage
      hasNextPage
      pageSize
      rows {
        id
        createdAt
        daysOff
        endDate
        startDate
        typeOfLeave {
          name
          description
        }
        status
      }
      total
    }
  }
`;

// Deprecated: use GET_LEAVE_REQUEST_BY_APPROVAL instead
export const GET_LEAVE_PENDING_APPROVALS = gql`
  query PendingApprovals($approvalId: String!, $pageParams: PageInput) {
    pendingApprovals(approvalId: $approvalId, pageParams: $pageParams) {
      total
      pageSize
      hasNextPage
      currentPage
      rows {
        id
        userName
        startDate
        endDate
        typeOfLeave {
          description
        }
      }
    }
  }
`;

export const GET_LEAVE_DETAILS = gql`
  query LeaveRequestDetails($leaveRequestId: String!) {
    leaveRequestDetails(leaveRequestId: $leaveRequestId) {
      id
      createdAt
      daysOff
      userId
      userName
      typeOfLeave {
        id
        description
        name
        type
      }
      status
      endDate
      startDate
      scheduleOptions
      evidences {
        evidenceType
        url
      }
      approvals {
        approvalRole
        approvalStatus
        order
        comment
        approvalId
      }
    }
  }
`;

export const GET_NEXT_LEAVE = gql`
  query NextLeave($userId: String!, $fromDate: String!) {
    nextLeave(userId: $userId, fromDate: $fromDate) {
      daysOff
      startDate
      endDate
      status
      id
    }
  }
`;

export const GET_USERS_ON_LEAVE = gql`
  query UsersOnLeave($sort: SortInput, $pageParams: PageInput) {
    usersOnLeave(sort: $sort, pageParams: $pageParams) {
      total
      pageSize
      hasNextPage
      currentPage
      rows {
        name
        startDate
        endDate
        daysOff
        assignationType
      }
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query User($email: String!) {
    user(email: $email) {
      assignationType
      country
      email
      firstName
      hiringDate
      id
      lastName
      isSupervisor
      leaveBalance {
        userId
        availableDays
        vacationDays
        additionalLeaveDays
        totalAdditionalLeaveDays
        usedAdditionalLeaves
        accumulatedDays
        daysToExpire {
          type
          days
          date
        }
        usedDays
      }
    }
  }
`;

export const GET_EVIDENCE_URL = gql`
  query UrlSignedEvidence($filename: String!) {
    urlSignedEvidence(filename: $filename)
  }
`;

export const GET_ADDITIONAL_LEAVES = gql`
  query Query($sort: SortInput, $pageParams: PageInput, $username: String) {
    additionalLeaves(
      sort: $sort
      pageParams: $pageParams
      username: $username
    ) {
      currentPage
      hasNextPage
      pageSize
      rows {
        id
        name
        expirationDate
        days
        activationDate
        userId
        type
        description
      }
      total
    }
  }
`;

export const GET_LEAVE_REQUEST_BY_APPROVAL = gql`
  query Query(
    $approvalId: String!
    $statusLeave: [LeaveStatus]
    $statusApproval: [ApprovalStatus]
    $onlyInOrder: Boolean!
    $sort: SortInput
    $pageParams: PageInput
    $isAdmin: Boolean!
  ) {
    leaveRequestsByApproval(
      approvalId: $approvalId
      statusLeave: $statusLeave
      statusApproval: $statusApproval
      onlyInOrder: $onlyInOrder
      sort: $sort
      pageParams: $pageParams
      isAdmin: $isAdmin
    ) {
      currentPage
      hasNextPage
      pageSize
      rows {
        id
        userName
        startDate
        endDate
        createdAt
        typeOfLeave {
          id
          name
          description
        }
        daysOff
        status
        assignationType
      }
      total
    }
  }
`;

export const GET_DAYS_TO_EXPIRE = gql`
  query UserDaysToExpire($sort: SortInput, $pageParams: PageInput) {
    daysToExpireList(sort: $sort, pageParams: $pageParams) {
      currentPage
      hasNextPage
      pageSize
      rows {
        date
        firstName
        daysToExpire
        lastName
        userId
      }
      total
    }
  }
`;

export const GET_STATE_REPORT = gql`
  query Query($filter: FilterReport) {
    currentStateReport(filter: $filter)
  }
`;
